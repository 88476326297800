import API, { graphqlOperation } from "@aws-amplify/api";
import Cache from "@aws-amplify/cache";
import moment from "moment";
import { listCompanyLocations } from "../graphql/queries";
import {
  createScheduleTimeblock,
  updateScheduleTimeblock,
  deleteScheduleTimeblock,
} from "../graphql/mutations";
import {
  execRead,
  execWrite,
  execReadBySortkey,
  execReadByPK,
} from "./DBService";
import * as scheduleQueries from "./ScheduleQueries";
import { listBookingsQuery } from "../queries/ListBookingsQueries";
import * as queries from "../graphql/queries";
import { toDate, utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
//TODO: Timezone has to be Location timezone or defaule timezone at company level
//TODO: Move date functions to its own DateUtil module
//The dates passed into the ScheduleService will be considered to be Location Timezone or Company Timezone
const SLOTSINADAY = 288;
const SLOTUNITMINS = 5;
const SLOTSINHOUR = 12;
const TIMEMAP = buildTimeMap();
const SCHEDPKSKSPLITAT = "::";
const ANYLOCATIONDESC = "'Any location' within travel radius";
const NOTRAVELTIMERADIUS = 1000; //in meters
const EARTHRADIUS = 6371e3; //in meters
const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const ALLDAYSARRAY = [0, 1, 2, 3, 4, 5, 6];
export const DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING = 7;
const DEFAULT_MAX_NUM_OF_SUGGESTED_SLOTS_TO_DISPALY = 10;
function getAnyLocationObj(companyId) {
  return {
    id: `${companyId}-ANY`,
    locationname: ANYLOCATIONDESC,
  };
}
const createSchedule = async ({
  user,
  providerId,
  locationId,
  locationType,
  companyId,
  //servicetypeId,
  services,
  active,
  internal,
  slotStartStep,
  instructions,
  postBookingInstructions,
}) => {
  const scheduleData = {
    id: getScheduleTimeblockId(companyId, providerId),
    scheduleinfo: getScheduleInfo(locationId, locationType, user),
    scheduleTimeblockProviderId: providerId,
    companyId,
    providerId,
    locationId,
    // servicetypeId,
    active,
    internal,
    slotStartStep,
    instructions,
    postBookingInstructions,
  };
  let response = await execWrite({
    opname: "createScheduleTimeblock",
    op: createScheduleTimeblock,
    input: scheduleData,
  });
  if (response && services && services.length > 0) {
    response = {
      ...response,
      id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`,
    };

    const saveServPromises = services.map(async (srv) => {
      const scheduleServiceData = {
        id: scheduleData.id,
        scheduleinfo: getScheduleInfoForService(scheduleData.scheduleinfo, srv),
        locationId: scheduleData.locationId,
        servicetypeId: srv,
        companyId,
        active,
        scheduleTimeblockProviderId: providerId,
      };
      const servResp = await execWrite({
        opname: "createScheduleTimeblock",
        op: createScheduleTimeblock,
        input: scheduleServiceData,
      });
    });
    await Promise.all(saveServPromises);
    response = { ...response, services };
  }

  return response;
};

const updateSchedule = async ({
  id,
  user,
  providerId,
  locationId,
  companyId,
  // servicetypeId,
  services,
  active,
  internal,
  slotStartStep,
  instructions,
  postBookingInstructions,
  originalServices,
}) => {
  //IMP:id passed in is composite of id:schedileinfo
  const pksk = id.split(SCHEDPKSKSPLITAT);
  const scheduleData = {
    id: pksk[0],
    scheduleinfo: pksk[1],
    companyId,
    providerId,
    locationId,
    // servicetypeId,
    active,
    internal,
    slotStartStep,
    instructions,
    postBookingInstructions,
    scheduleTimeblockProviderId: providerId,
  };

  let response = await execWrite({
    opname: "updateScheduleTimeblock",
    op: updateScheduleTimeblock,
    input: scheduleData,
  });
  if (response) response = { ...response, id };

  //check if any services changed
  const removeOriginalService = [];
  const addNewService = [];
  originalServices.forEach((os) => {
    //os.servicetypeId
    let foundO = false;
    services.forEach((s) => {
      if (s === os.servicetypeId) {
        foundO = true;
      }
    });
    if (!foundO) removeOriginalService.push(os);
  });
  services.forEach((s) => {
    //os.servicetypeId
    let foundO = false;
    originalServices.forEach((os) => {
      if (s === os.servicetypeId) {
        foundO = true;
      }
    });
    if (!foundO) addNewService.push(s);
  });

  if (removeOriginalService.length > 0) {
    const delPromises = removeOriginalService.map(async (os) => {
      await execWrite({
        opname: "deleteScheduleTimeblock",
        op: deleteScheduleTimeblock,
        input: {
          id: pksk[0],
          scheduleinfo: os.scheduleinfo,
        },
      });
    });

    const delServResp = await Promise.all(delPromises);
  }

  if (addNewService && addNewService.length > 0) {
    const saveServPromises = addNewService.map(async (srv) => {
      const scheduleServiceData = {
        id: scheduleData.id,
        scheduleinfo: getScheduleInfoForService(scheduleData.scheduleinfo, srv),
        locationId: scheduleData.locationId,
        servicetypeId: srv,
        companyId,
        active,
        scheduleTimeblockProviderId: providerId,
      };
      const servResp = await execWrite({
        opname: "createScheduleTimeblock",
        op: createScheduleTimeblock,
        input: scheduleServiceData,
      });
    });
    await Promise.all(saveServPromises);
    response = { ...response, services: addNewService };
  }

  return response;
};

async function _deleteSchedule(pk, sk) {
  const input = {
    id: pk,
    scheduleinfo: sk,
  };
  let response = null;
  try {
    response = await execWrite({
      opname: "deleteScheduleTimeblock",
      op: deleteScheduleTimeblock,
      input: input,
    });
  } catch (error) {
    console.log(error);
    return {
      error,
    };
  }
  return response;
}

async function activateSchedule(schCompositeId) {
  return await changeActivenessOfSchedule(schCompositeId, true);
}

async function deactivateSchedule(schCompositeId) {
  return await changeActivenessOfSchedule(schCompositeId, false);
}

async function changeActivenessOfSchedule(schCompositeId, activeness) {
  const pksk = schCompositeId.split(SCHEDPKSKSPLITAT);
  const scheduleData = {
    id: pksk[0],
    scheduleinfo: pksk[1],
    active: activeness,
  };

  let response = await execWrite({
    opname: "updateScheduleTimeblock",
    op: updateScheduleTimeblock,
    input: scheduleData,
  });
  if (response) response = { ...response, id: schCompositeId };
  return response;
}

function getScheduleInfo(locationId, locationType, user) {
  return `SC|${locationType}-${locationId}|${user.substring(
    0,
    3
  )}${getRandomInt(1000)}-${Date.now()}`;
}
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
function getScheduleInfoForTimeblock(
  schedulesk,
  user,
  tbtype,
  startDate,
  startTime
) {
  if (typeIsABooking(tbtype)) {
    return `BK|${startDate} ${startTime}|${schedulesk}|${(
      "" + Date.now()
    ).slice(-6)}`;
  } else if (typeIsUnavailable(tbtype)) {
    return `SBU|${startDate} ${startTime}|${user.substring(0, 3)}${getRandomInt(
      1000
    )}-${Date.now()}`;
  } else
    return `SB|${schedulesk}|${user.substring(0, 3)}${getRandomInt(
      1000
    )}-${Date.now()}`;
}
function getScheduleInfoForService(schedulesk, servid) {
  return `SR|${servid}|${schedulesk}`;
}

function typeIsABooking(tbtype) {
  if (tbtype === "BOOKED") return true;
  else return false;
}

function typeIsUnavailable(tbtype) {
  if (tbtype === "UNAVAILABLE") return true;
  else return false;
}

const createUnavailableTimeblock = async (input) => {
  //input contains providerId
  const { providerId, startDate, startTime, companyId, user } = input;
  const scheduleId = `${getScheduleTimeblockId(
    companyId,
    providerId
  )}${SCHEDPKSKSPLITAT}${getScheduleInfoForTimeblock(
    null,
    user,
    "UNAVAILABLE",
    startDate,
    startTime
  )}`;
  const response = await createTimeblock({ ...input, scheduleId });
  return response;
};

const createTimeblock = async ({
  user,
  companyId,
  locationId,
  scheduleId,
  startDate,
  endDate,
  startTime,
  endTime,
  type,
  status,
  weeksToRepeat,
  weekDays,
  active,
  instructions,
  tz,
  sdtutc,
}) => {
  const weekdaysStr = weekDays ? weekdaysString(weekDays) : weekDays;
  const pksk = scheduleId.split(SCHEDPKSKSPLITAT);

  const timeblockData = {
    id: pksk[0],
    scheduleinfo: getScheduleInfoForTimeblock(
      pksk[1],
      user,
      type,
      startDate,
      startTime
    ),
    companyId,
    locationId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    active,
    weeksToRepeat,
    ...(weekdaysStr && { weekDays: weekdaysStr }),
    scheduleTimeblockProviderId: pksk[0].split("|")[1].slice(2),
    instructions,
    tz,
    sdtutc,
  };
  let response = await execWrite({
    opname: "createScheduleTimeblock",
    op: createScheduleTimeblock,
    input: timeblockData,
  });
  if (response) {
    response = {
      ...response,
      id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`,
    };
  }
  return response;
};

const updateTimeblock = async ({
  id,
  companyId,
  locationId,
  startDate,
  endDate,
  startTime,
  endTime,
  type,
  status,
  weeksToRepeat,
  weekDays,
  active,
  tz,
  sdtutc,
}) => {
  const pksk = id.split(SCHEDPKSKSPLITAT);

  const weekdaysStr = weekDays ? weekdaysString(weekDays) : weekDays;

  const timeblockData = {
    id: pksk[0],
    scheduleinfo: pksk[1],
    companyId,
    locationId,
    startDate,
    endDate,
    startTime,
    endTime,
    type,
    status,
    active,
    weeksToRepeat,
    ...(weekdaysStr && { weekDays: weekdaysStr }),
    scheduleTimeblockProviderId: pksk[0].split("|")[1].slice(2),
    tz,
    sdtutc,
  };

  let response = await execWrite({
    opname: "updateScheduleTimeblock",
    op: updateScheduleTimeblock,
    input: timeblockData,
  });
  if (response) {
    response = {
      ...response,
      id: `${response.id}${SCHEDPKSKSPLITAT}${response.scheduleinfo}`,
    };
  }
  return response;
};

async function getScheduleCounts(companyId) {
  const SCs = await execReadBySortkey({
    opname: "schedulesByCompany",
    op: scheduleQueries.schedulesByCompanyForCount,
    id: { companyId },
    skey: { scheduleinfo: { beginsWith: "SC|" } }, //TODO: shhould be based on StartDate and EndDate
    filter: { active: { ne: false } },
    limit: 100,
  });

  let scheduleCounts = {};
  if (SCs && SCs.items) {
    for (let sc of SCs.items) {
      if (scheduleCounts.hasOwnProperty(sc.providerId)) {
        scheduleCounts[sc.providerId] = scheduleCounts[sc.providerId] + 1;
      } else {
        scheduleCounts[sc.providerId] = 1;
      }
    }
  }
  return scheduleCounts;
}

function scheduleHasService(schItem, schedsByService) {
  let found = false;
  if (schedsByService && schedsByService.length > 0) {
    schedsByService.forEach((sbs) => {
      if (sbs.scheduleinfo.endsWith(schItem.scheduleinfo)) found = true;
    });
  }
  return found;
}

const getSlotsByScheduleId = async ({
  scheduleid,
  startdate,
  numdays,
  timeofday, //not passed in yet
  bookingIntervalMinutes,
  serviceDuration,
  travelTime,
  geoLoc,
  bookingIncrement,
  providerTz,
}) => {
  travelTime = travelTime ? travelTime : 0;
  bookingIntervalMinutes = bookingIntervalMinutes
    ? Number(bookingIntervalMinutes)
    : 0 === Number(bookingIntervalMinutes)
    ? 0
    : 0;
  const pksk = !!scheduleid && scheduleid.split(SCHEDPKSKSPLITAT);
  const startingDate = startdate ? startdate : getNowDate();
  const endingDate = getEndingDate(startingDate, numdays);
  const locationTz = await getLocationTimezone(pksk[1]);
  if (!geoLoc) {
    // this booking is for company location
    // so get geo location of the company location
    geoLoc = await getCompanyLocationGeoCoordinates(pksk[1]);
  }
  let scheduleData = {};
  const schResp = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listAllScheduleItems,
    id: { id: pksk[0] },
    skey: { scheduleinfo: { eq: pksk[1] } },
    filter: { active: { eq: true } },
    limit: 500,
  });
  if (schResp && schResp.items) {
    const schsWithCompId = schResp.items.map((sch) => {
      return { ...sch, id: `${sch.id}${SCHEDPKSKSPLITAT}${sch.scheduleinfo}` };
    });
    scheduleData = { ...scheduleData, schedules: schsWithCompId };
  }
  const avandunavBlocksResp = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listAllScheduleItems,
    id: { id: pksk[0] },
    skey: { scheduleinfo: { beginsWith: "SB" } },
    filter: { active: { ne: false } },
    limit: 500,
  });
  if (avandunavBlocksResp && avandunavBlocksResp.items)
    scheduleData = { ...scheduleData, tbs: avandunavBlocksResp.items };

  // adjust SBUs to booking location timezone
  if (providerTz) {
    const SBUs = scheduleData.tbs?.filter((b) =>
      b.scheduleinfo.startsWith("SBU|")
    );
    if (SBUs && SBUs.length)
      adjustSBUTimesForBookingLocationTimezone(SBUs, providerTz, locationTz);
  }

  // end..adjust SBUs to booking location timezone
  const bookedResp = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: {
      scheduleinfo: { between: [`BK|${startingDate}`, `BK|${endingDate}`] },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });

  if (bookedResp && bookedResp.items) {
    const found = bookedResp.items.find((bk) =>
      bk.scheduleinfo.includes("|CL-")
    );
    if (found) {
      //booking at company location found, so get geoLocs of the company locations
      const companyLocations = await getCompanyLocations(getCompanyId(pksk[0]));
      for (const companyLoc of companyLocations) {
        for (const booking of bookedResp.items) {
          if (
            booking.scheduleinfo.includes(companyLoc.id) &&
            companyLoc.latitude &&
            companyLoc.longitude
          ) {
            booking.locationId = `${companyLoc.latitude}|${companyLoc.longitude}`;
          }
        }
      }
    }
  }

  if (bookedResp && bookedResp.items) {
    // let locationTz = "America/Vancouver";
    adjustGlobalBookingTimesForBookingLocationTimezone(
      locationTz,
      bookedResp.items,
      serviceDuration
    );
    scheduleData = { ...scheduleData, booked: bookedResp.items };
  }

  const slotsinfo = scheduleData.schedules.map((schedule) => {
    const avTBs = scheduleData.tbs.filter(
      (tb) => tb.scheduleinfo.indexOf(schedule.scheduleinfo) > 0
    );
    const unavTBs = scheduleData.tbs.filter((tb) =>
      tb.scheduleinfo.startsWith("SBU")
    );
    let tbs = [];
    if (avTBs && avTBs.length > 0) tbs = tbs.concat(avTBs);
    if (unavTBs && unavTBs.length > 0) tbs = tbs.concat(unavTBs);

    const unreadableSlots = computeAvailableSlots(
      tbs,
      scheduleData.booked,
      startingDate,
      numdays,
      bookingIncrement, // !!schedule.slotStartStep ? schedule.slotStartStep : 60,
      travelTime,
      geoLoc
    );

    const readableSlots = toReadableSlots(
      schedule,
      unreadableSlots.filter((urs) => urs.hasavail),
      bookingIncrement, // !!schedule.slotStartStep ? schedule.slotStartStep : 60,
      serviceDuration
    );
    return {
      schedule: {
        id: schedule.id,
        scheduleinfo: schedule.scheduleinfo,
        companyId: schedule.companyId,
        providerId: schedule.providerId,
        locationId: schedule.locationId,
        avtbs: avTBs,
      },
      slots: readableSlots,
    };
  });
  //TODO: return error if no schedule is present
  const futureSlotsOnly = filterOutTodaysPastSlots(
    slotsinfo,
    bookingIntervalMinutes
  );

  const s = !timeofday[0]
    ? filterByTimesofday(futureSlotsOnly, timeofday)
    : futureSlotsOnly; //Validate timeofday (1 for morning, 2 for afternoon, 3 for evening)
  return s;
};
function getCompanyId(ScheduleTimeblockPk) {
  const parts = ScheduleTimeblockPk.split("|");
  return parts[0]?.slice(2, parts[0].length);
}
async function getCompanyLocations(companyId) {
  try {
    let locationData = await execReadByPK({
      opname: "companyLocationByCompany",
      op: /* GraphQL */ `
        query CompanyLocationByCompany(
          $companyId: String
          $locationnameCreatedAt: ModelCompanyLocationByCompanyCompositeKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelCompanyLocationFilterInput
          $limit: Int
          $nextToken: String
        ) {
          companyLocationByCompany(
            companyId: $companyId
            locationnameCreatedAt: $locationnameCreatedAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              locationname
              longitude
              latitude
              timezone
              virtual
              active
              deleted
            }
            nextToken
          }
        }
      `,
      id: {
        companyId,
      },
      limit: 500,
    });

    if (locationData?.items && locationData?.items.length > 0) {
      return locationData.items;
    }
    return [];
  } catch (e) {
    console.log("error in ScheduleService getCompanyLocations", e);
    return [];
  }
}

function adjustGlobalBookingTimesForBookingLocationTimezone(
  locationTz,
  bookings,
  serviceDuration
) {
  for (let b of bookings) {
    if (b.sdtutc && b.startTime && b.endTime) {
      const bookedMinutes = calculateBookedMinutes(b.startTime, b.endTime);
      const startDateUTC = new Date(b.sdtutc);
      const startDateInLocZone = utcToZonedTime(startDateUTC, locationTz);
      b.startDate = format(startDateInLocZone, "yyyy-MM-dd", {
        timeZone: locationTz,
      });
      b.startTime = format(startDateInLocZone, "HH:mm", {
        timeZone: locationTz,
      });
      const endDateUTC = new Date(startDateInLocZone);
      endDateUTC.setMinutes(endDateUTC.getMinutes() + bookedMinutes);
      b.endTime = format(endDateUTC, "HH:mm", locationTz);
    }
  }
}

function adjustSBUTimesForBookingLocationTimezone(
  SBUs,
  providerTz,
  locationTz
) {
  for (let sbu of SBUs) {
    const startDateTimeinUtc = zonedTimeToUtc(
      `${sbu.startDate}T${sbu.startTime}:00`,
      providerTz
    );
    const startDateTimeInBookingLoc = utcToZonedTime(
      startDateTimeinUtc,
      locationTz
    );
    sbu.startDate = format(startDateTimeInBookingLoc, "yyyy-MM-dd");
    sbu.startTime = format(startDateTimeInBookingLoc, "HH:mm");

    const endDateTimeinUtc = zonedTimeToUtc(
      `${sbu.endDate}T${sbu.endTime}:00`,
      providerTz
    );
    const endDateTimeInBookingLoc = utcToZonedTime(
      endDateTimeinUtc,
      locationTz
    );
    sbu.endDate = format(endDateTimeInBookingLoc, "yyyy-MM-dd");
    sbu.endTime = format(endDateTimeInBookingLoc, "HH:mm");
  }
}

function calculateBookedMinutes(bkstartTime, bkendTime) {
  //this works only if start and end times are within the same date
  //Jan 10th 11pm to Jan 11th 1am won't work.
  const startHHMM = bkstartTime.split(":");
  const endHHMM = bkendTime.split(":");
  const startTime = new Date(2000, 1, 1, startHHMM[0], startHHMM[1]);
  const endTime = new Date(2000, 1, 1, endHHMM[0], endHHMM[1]);
  const diff = endTime.valueOf() - startTime.valueOf();
  const bookedMinutes = diff / (1000 * 60);
  return bookedMinutes;
}

function filterOutTodaysPastSlots(slots, bookingIntervalMinutes) {
  const checkDateTime = new Date(
    Date.now() + bookingIntervalMinutes * 60 * 1000
  );
  slots.forEach((e) => {
    e.slots.forEach((day) => {
      if (day.date.getTime() < checkDateTime.getTime())
        day.slots = day.slots.filter((slot) =>
          isFutureSlot_v3(slot, day.date, checkDateTime)
        );
    });
  });

  return slots;
}

function filterByTimesofday(slots, timesofday) {
  slots.forEach((e) => {
    e.slots.forEach((day) => {
      day.slots = day.slots.filter((slot) => timesofday[slot.tfd]);
    });
  });

  return slots;
}

function isFutureSlot_v3(slot, dayDate, checkDateTime) {
  //Calculate millis of the slot
  const dayDateMillis = dayDate.getTime();
  let slotDate = new Date(dayDateMillis);
  slotDate.setHours(Number.parseInt(slot.start24.slice(0, 2)));
  slotDate.setMinutes(Number.parseInt(slot.start24.slice(-2)));
  return slotDate.getTime() > checkDateTime.getTime();
}

function computeAvailableSlots(
  tb,
  booked,
  sd,
  nd,
  bookingIncrement,
  travelTime,
  geoLoc
) {
  travelTime = travelTime ? travelTime : 0;
  const sdate = awsDateToJsDate(sd); //let it be local browser date
  //Create the placeholder array of by-date slots objects for asked numdays
  //For earch day create slots array based on step (15,30,60 mins)
  let slotMapArray = new Array(nd).fill(null).map((a, b, c) => ({
    date: new Date(sdate.getFullYear(), sdate.getMonth(), sdate.getDate() + b),
    stiEtiArr: [],
    hasavail: false,
    slotmap: new Array(SLOTSINADAY), // initSlotMap(step)
  }));
  //Check availability templates
  //iterate over the AVAILABLE timeblocks
  //  for each AVAILABLE timeblock
  //    based on startTime and endTime (and step), calculate start index and stop index to be marked as 1 (meaning available) [TBD: it is possible to have this number higher than 1 indicating that multiple booking is possible but need to design the feature end-to-end]
  //     Once start and stop indices are calculated, for each day in the slotMapArray which falls within the AVAILABLE timeblock's range and it is the workingday of the provider, fill the availability in each day's slotmap

  tb.filter((timeblock) => timeblock.type === "AVAILABLE").forEach(
    (timeblock) => {
      slotMapArray
        .filter((slotmap) => {
          return (
            slotmap.date >= awsDateToJsDate(timeblock.startDate) &&
            slotmap.date <= awsDateToJsDate(timeblock.endDate) &&
            worksonday(slotmap.date, timeblock.weekDays)
          );
        })
        .forEach((slotmap) => {
          slotmap.hasavail = true;
          const sti = getIndexByTime(timeblock.startTime);
          const eti = getIndexByTime(timeblock.endTime);
          slotmap.stiEtiArr.push({ sti, eti });
          markAvailability(slotmap.slotmap, sti, eti);
        });
    }
  );

  //TODO: do we need to check for UNAVAILABLE?
  tb.filter((timeblock) => timeblock.type === "UNAVAILABLE").forEach(
    (timeblock) => {
      slotMapArray
        .filter(
          (slotmap) =>
            slotmap.hasavail &&
            hasUnavailability(
              slotmap.date,
              awsDateToJsDate(timeblock.startDate),
              awsDateToJsDate(timeblock.endDate),
              timeblock.weekDays
            )
        )
        .forEach((slotmap) => {
          markUnAvailability(
            slotmap.slotmap,
            getIndexByTime(timeblock.startTime),
            getIndexByTime(timeblock.endTime),
            0, //Travel timee zero for
            null,
            null,
            bookingIncrement
          );
        });
    }
  );
  // iterate over BOOKED blocks (appointments already booked)
  // For each booked appointment,
  //    compute start and stop index based on the start and end time of the appointment
  //    get the date of the appointment
  //    find the day in slotMapArray that matches the appointment date
  //    for the matched day in the slotMapArray, mark the slotmap's elements as booked (i.e. unavailable)
  booked
    .filter((timeblock) => timeblock.type === "BOOKED")
    .forEach((timeblock) => {
      slotMapArray
        .filter(
          (slotmap) =>
            slotmap.hasavail &&
            slotmap.date.getTime() ===
              awsDateToJsDate(timeblock.startDate).getTime()
        )
        .forEach((slotmap) => {
          markUnAvailability(
            slotmap.slotmap,
            getIndexByTime(timeblock.startTime),
            getIndexByTime(timeblock.endTime),
            travelTime,
            timeblock,
            geoLoc,
            bookingIncrement
          );
        });
    });
  return slotMapArray;
}

function hasUnavailability(
  slotmapDate,
  unavStartDate,
  unavEndDate,
  unavWeekDays
) {
  // if SBU block has weekdays, weekday must match
  // otherwise only date match is required

  if (
    slotmapDate.getTime() >= unavStartDate.getTime() &&
    slotmapDate.getTime() <= unavEndDate.getTime()
  ) {
    if (unavWeekDays && unavWeekDays.length) {
      let unavaOnWeekday =
        unavWeekDays.indexOf(slotmapDate.getDay()) !== -1 ? true : false;
      if (!unavaOnWeekday) return false;
    }
    return true;
  } else return false;
}
//sma slotMapArray, step 15, 30, 60 mins
function toReadableSlots(schedule, sma, step, serviceDuration) {
  const readableArray = [];
  sma.forEach((d) => {
    readableArray.push({
      schid: schedule.id,
      date: d.date,
      slots: toReableArray(
        d.slotmap,
        step,
        d.date,
        d.stiEtiArr,
        serviceDuration
      ),
    });
  });
  return readableArray;
}
//slotmap array of 0s and 1s indicating the availability
//convert info in the slotmap into the start times of the available slots
function toReableArray(slotmap, step, date, stiEtiArr, serviceDuration) {
  const readableSlots = [];
  const displaybleSlots = displaySlotsByStep2(
    step,
    slotmap,
    TIMEMAP,
    serviceDuration,
    stiEtiArr
  );
  if (displaybleSlots.length > 0) {
    displaybleSlots.forEach((ds) => {
      const datetime = new Date(date.getTime());
      datetime.setHours(ds.hr);
      datetime.setMinutes(ds.mins);
      readableSlots.push({
        len: step,
        start24: ds.tstr24,
        start12: ds.tstr12,
        datetime,
        tfd: ds.tfd,
        hr: ds.hr,
      });
    });
  }

  return readableSlots;
}

function getNowDate() {
  return getAWSDate(new Date()); //local datetime
}

function getAWSDateOf(date) {
  return getAWSDate(date);
}

function getAWSDate(date) {
  let oy = { year: "numeric" };
  let YYYY = date.toLocaleDateString("en-US", oy);
  let om = { month: "2-digit" };
  let MM = date.toLocaleDateString("en-US", om);
  let od = { day: "2-digit" };
  let DD = date.toLocaleDateString("en-US", od);
  return `${YYYY}-${MM}-${DD}`;
}

function getEndingDate(sd, nd) {
  const ed = awsDateToJsDate(sd);
  ed.setDate(ed.getDate() + nd);
  return getAWSDate(ed);
}

function awsDateToJsDate(awsDate) {
  // from YYYY-MM-DD to local timezone
  const dateparts = awsDate.split("-");
  return new Date(
    parseInt(dateparts[0]),
    parseInt(dateparts[1] - 1),
    parseInt(dateparts[2])
  ); //yes, local time
}

function awsDateToJsEndDate(awsDate) {
  const d = awsDateToJsDate(awsDate);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
}

function worksonday(sample, range) {
  if (range && sample) {
    return range.indexOf(sample.getDay()) !== -1 ? true : false;
  } else return true; //no range, so weekday does not matter.
}

function weekdaysString(wdArray) {
  return "" + wdArray.join("");
}
const isbool = (val) => "boolean" === typeof val;

function getByValue(arr, value) {
  if (!arr) return null;
  var result = arr.filter(function (o) {
    return o.id == value;
  });
  return result?.length
    ? { id: result[0].id, locationname: result[0].locationname }
    : null;
}

function areSame(d1, d2) {
  return (
    d1 &&
    d2 &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
}
// aDate - appointment date and time to check for validity
// dayCount - number associated with the unit in dayType
// dayType - days, weeks, months
const validateApptDate = async (
  aDate,
  scheduleid,
  dayCount,
  dayType,
  bookingIntervalMinutes,
  serviceDuration,
  travelTime,
  geoLoc,
  timeofday,
  bookingIncrement
) => {
  travelTime = travelTime ? travelTime : 0;

  //Get timeblocks
  const availableTBs = await getTimeblocksbyScheduleId(scheduleid);
  //if apptDate's day has the schedule
  const doesworkondate = isScheduleAvailableOnApptDate(aDate, availableTBs);
  if (doesworkondate.result) {
    //provider works on the booking date
    /////....... start of new Code
    const slotsBySchedule = await getSlotsByScheduleId({
      scheduleid: scheduleid,
      startdate: getAWSDate(aDate),
      numdays: 1,
      bookingIntervalMinutes,
      serviceDuration,
      travelTime,
      geoLoc,
      timeofday,
      bookingIncrement,
    });
    const slotsByDate = slotsBySchedule[0].slots;
    const apptTime24hr = get24hrTime(aDate);
    for (const day of slotsByDate) {
      if (day && areSame(day.date, aDate))
        for (const slot of day.slots) {
          if (slot.start24 === apptTime24hr) {
            return {
              isValid: true,
              hasAlternate: false,
              alternateSlot: {},
              originalSlot: aDate,
              dateInSchedule: doesworkondate.dateInSchedule,
            };
          }
        }
    }
    for (const day of slotsByDate) {
      if (day && areSame(day.date, aDate)) {
        if (day && day.slots && day.slots.length) {
          for (const slot of day.slots) {
            slot.diff = Math.abs(slot.datetime.getTime() - aDate.getTime());
          }
          day.slots.sort(function (a, b) {
            return a.diff - b.diff;
          });
          return {
            isValid: false,
            hasAlternate: true,
            alternateSlot: { date: day.date, slot: day.slots[0] },
            originalSlot: aDate,
            dateInSchedule: doesworkondate.dateInSchedule,
          };
        } else {
          return {
            isValid: false,
            hasAlternate: false,
            alternateSlot: {},
            originalSlot: aDate,
            dateInSchedule: doesworkondate.dateInSchedule,
          };
        }
      }
    }
    ///// End of new Code.....................
  } else {
    //suggest alternate

    //Get slots for one week
    const slotSearchDateRange = decideAlternateSlotSearchDateRange(
      aDate,
      dayCount,
      dayType
    );
    if (slotSearchDateRange.result === false) {
      return {
        isValid: false,
        hasAlternate: false,
        alternateSlot: {},
        originalSlot: aDate,
        dateInSchedule: doesworkondate.dateInSchedule,
      };
    }
    const slotsBySchedule = await getSlotsByScheduleId({
      scheduleid: scheduleid,
      startdate: getAWSDate(slotSearchDateRange.startDate),
      numdays: slotSearchDateRange.numdays,
      bookingIntervalMinutes,
      serviceDuration,
      travelTime,
      geoLoc,
      timeofday,
      bookingIncrement,
    });
    const slotsByDate = slotsBySchedule[0].slots;
    const apptTime24hr = get24hrTime(aDate);
    for (const day of slotsByDate) {
      for (const slot of day.slots) {
        if (slot.start24 === apptTime24hr) {
          return {
            isValid: false,
            hasAlternate: true,
            alternateSlot: { date: day.date, slot: slot },
            originalSlot: aDate,
            dateInSchedule: doesworkondate.dateInSchedule,
          };
        }
      }
    }
  }
  return {
    isValid: false,
    hasAlternate: false,
    alternateSlot: {},
    originalSlot: aDate,
    dateInSchedule: doesworkondate.dateInSchedule,
  };
};

const decideAlternateSlotSearchDateRange = (apptDate, dayCount, dayType) => {
  if (dayType === "days" && dayCount === 1) {
    //No alternate slot can be searched
    return { result: false };
  }
  if (dayType === "days") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 2 ? 0 : dayCount - 1,
    };
  }
  if (dayType === "weeks") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 1 ? 3 : (dayCount * 7) / 2,
    };
  }
  if (dayType === "months") {
    return {
      result: true,
      startDate: addDays(apptDate, 1),
      numdays: dayCount === 1 ? 14 : (dayCount * 30) / 2,
    };
  }
  return {
    result: false,
  };
};

function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

const isScheduleAvailableOnApptDate = (aDate, avTBs) => {
  const ret = {
    result: false,
    dosuggestalternate: false,
    dateInSchedule: false,
  };
  let dateInSchedule = false;
  for (let tb of avTBs) {
    if (
      aDate >= awsDateToJsDate(tb.startDate) &&
      aDate <= awsDateToJsEndDate(tb.endDate)
    ) {
      dateInSchedule = true;
    }
  }
  ret.dateInSchedule = dateInSchedule;
  for (let tb of avTBs) {
    if (
      aDate >= awsDateToJsDate(tb.startDate) &&
      aDate <= awsDateToJsEndDate(tb.endDate)
    ) {
      if (worksonday(aDate, tb.weekDays)) {
        ret.result = true;
        ret.dosuggestalternate = false;
      } else {
        if (!ret.result) {
          ret.result = false;
          ret.dosuggestalternate = true;
        }
      }
    } else {
      if (!ret.result) {
        ret.result = false;
        ret.dosuggestalternate = false;
      }
    }
  }
  return ret;
};

const getTimeblocksbyScheduleId = async (scheduleid) => {
  const cachedblocks = Cache.getItem("tbs-of-schedule-" + scheduleid);
  if (cachedblocks) return cachedblocks;
  const pksk = scheduleid.split(SCHEDPKSKSPLITAT);
  const blocks = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
    filter: {
      and: [{ active: { ne: false } }, { deleted: { ne: true } }],
    },
    limit: 20,
  });
  Cache.setItem("tbs-of-schedule-" + scheduleid, blocks.items, {
    expires: Date.now() + 60000,
  }); //keep the schedule in cache only for short duration
  return blocks.items;
};

const getTimeblocksbyScheduleIdNoCache = async (scheduleid) => {
  const pksk = scheduleid.split(SCHEDPKSKSPLITAT);
  const blocks = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
    filter: null,
    limit: 20,
  });
  return blocks.items;
};

const getSBsByScheduleInfo = async (pk, schInfo) => {
  const blocks = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pk },
    skey: { scheduleinfo: { beginsWith: `SB|${schInfo}` } },
    filter: null,
    limit: 20,
  });
  if (blocks.items) {
    for (let tb of blocks.items) {
      createScheduleDescription(tb);
    }
  }
  return blocks.items;
};

function createScheduleDescription(tb) {
  tb.timeRange = `${withAMPM(tb.startTime)} - ${withAMPM(tb.endTime)}`;
  tb.dateRange = `${formatted(tb.startDate)} - ${formatted(tb.endDate)}`;
  const weekDaysArr =
    tb?.weekDays === "" ? ALLDAYSARRAY : tb.weekDays.split("");
  if (weekDaysArr) {
    weekDaysArr.sort();
    let repeat = "";
    if (weekDaysArr.length === 7) {
      repeat = "Every day";
    } else if (weekDaysArr.length === 5 && isWeekdaysOnly(weekDaysArr)) {
      repeat = "Every weekday";
    } else {
      repeat = "Every ";
      const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      for (const we of weekDaysArr) {
        repeat = repeat + weeks[we] + " ";
      }
    }
    tb.repeat = repeat;
  }
}

function isWeekdaysOnly(wa) {
  wa.sort();
  for (let i = 0; i <= 4; i++) {
    if (!(parseInt(wa[i]) === i + 1)) return false;
  }
  return true;
}
function formatted(d) {
  return new Date(d).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "short",
    day: "numeric",
  });
}

function withAMPM(t) {
  if (!t) return "";
  const hm = t.split(":");
  let hh = Number.parseInt(hm[0]);
  let hr = hh === 0 ? 12 : hh < 13 ? hh : hh - 12;
  let hrstr = hr;
  let ampm = hh < 12 ? "AM" : "PM";
  if (hm[1] === "00") return `${hrstr} ${ampm}`;
  else return `${hrstr}:${hm[1]} ${ampm}`;
}

async function getLocationTimezone(scheduleinfo) {
  const parts = scheduleinfo.split("|");
  const locationId = parts[1].slice(3);
  if (scheduleinfo && scheduleinfo.includes("|PL-")) {
    const providerLocation = await getProviderLocation(locationId);
    if (providerLocation) {
      return providerLocation.timezone;
    }
  }
  if (scheduleinfo && scheduleinfo.includes("|CL-")) {
    const companyLocation = await getCompanyLocation(locationId);
    if (companyLocation) {
      return companyLocation.timezone;
    }
  }
  return BROWSER_TZ;
}

const getProviderLocation = async (id) => {
  const data = await API.graphql(
    graphqlOperation(
      `query GetProviderLocation($id: ID!) {
      getProviderLocation(id: $id) {
        id
        name 
        timezone
      }
    }`,
      {
        id,
      }
    )
  );
  return data.data.getProviderLocation;
};

const getCompanyLocationGeoCoordinates = async (scheduleinfo) => {
  try {
    if (scheduleinfo && scheduleinfo.includes("|CL-")) {
      const parts = scheduleinfo.split("|");
      const locationId = parts[1].slice(3);
      const companyLocation = await getCompanyLocation(locationId);
      if (companyLocation) {
        return {
          lat: companyLocation.latitude,
          lng: companyLocation.longitude,
        };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};
const getCompanyLocation = async (id) => {
  const data = await API.graphql(
    graphqlOperation(
      `query GetCompanyLocation($id: ID!) {
        getCompanyLocation(id: $id) {
        id
        locationname 
        timezone
        latitude
        longitude
      }
    }`,
      {
        id,
      }
    )
  );
  return data.data.getCompanyLocation;
};

const checkServiceHasExistingSchedule = async (
  checkTheseServices,
  companyId,
  providerId,
  locationId
) => {
  //SR|8ac413d9-20df-4b28-9a5c-4d75e5125cfd|SC|CL-7128a9e4-07ed-4b84-acb6-99149a794f10
  const srs = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listSchedules,
    id: { id: getScheduleTimeblockId(companyId, providerId) },
    skey: { scheduleinfo: { beginsWith: `SR|` } },
    filter: { locationId: { eq: locationId.slice(3) } },
    limit: 20,
  });
  let returnServices = [];
  if (srs && srs.items) {
    returnServices = srs.items
      .filter((sr) => {
        let found = false;
        for (let checkSr of checkTheseServices) {
          if (checkSr === sr.servicetypeId) {
            found = true;
            break;
          }
        }
        return found;
      })
      .map((sr) => sr.servicetypeId);
  }
  return returnServices;
};

const get24hrTime = (aDate) => {
  return aDate
    ? aDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h24",
      })
    : "";
};
function getIndexByTime(t) {
  const parts = t.split(":");
  const h = parseInt(parts[0]);
  const m = parseInt(parts[1]);
  const index = (h * 60 + m) / SLOTUNITMINS;
  return index;
}

function markAvailability(sm, si, ei) {
  if (ei === 0) ei = SLOTSINADAY;
  for (let s = si; s <= ei - 1; s++) {
    sm[s] = 1;
  }
}

function markUnAvailability(
  sm,
  si,
  ei,
  travelTime,
  tb,
  geoLoc,
  bookingIncrement
) {
  if (tb && tb.locationId && geoLoc) {
    if (!includeTravelTime(tb, geoLoc)) travelTime = 0;
  }
  if (travelTime && travelTime > 0) {
    let ttIndLen = travelTime / SLOTUNITMINS;
    si = si - ttIndLen;
    ei = ei + ttIndLen;
    let clawbackInd = ei % (bookingIncrement / SLOTUNITMINS);
    if (clawbackInd <= 1) {
      //10 mins into the next half an hour like 10:10 or 10:40
      ei = ei - clawbackInd;
    }
  }
  //in above block ei could correspond to endTime 00:00 i.e. ei=0 (or near midnight like 11:50pm), and hence its travel time adjusted ei could be between 0 and 5 of the NEXT  DAY.
  // we have no way of storing and presenting this fact to users so reset the endTime back 00:00
  if (ei < si) ei = SLOTSINADAY;

  for (let s = si; s <= ei - 1; s++) {
    sm[s] = 0;
  }
}

function includeTravelTime(tb, geoLoc) {
  if (tb && tb.locationId && geoLoc) {
    let tbll = tb.locationId.split("|");
    if (!tbll || !tbll.length === 2) return true;
    const tbLat = Number(tbll[0]);
    const tbLng = Number(tbll[1]);
    const bookLat = Number(geoLoc.lat);
    const bookLng = Number(geoLoc.lng);
    const lat = tbLat - bookLat;
    const lng = tbLng - bookLng;
    const dLat = (lat * Math.PI) / 180;
    const dLng = (lng * Math.PI) / 180;
    const bookLatRad = (bookLat * Math.PI) / 180;
    const tbLatRad = (tbLat * Math.PI) / 180;

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(bookLatRad) *
        Math.cos(tbLatRad) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = EARTHRADIUS * c;
    if (d < NOTRAVELTIMERADIUS) return false;
  }
  return true;
}

function calcDist(fromGeo, toGeo) {
  // fromGeo.latitude, fromGeo.longitude
  // toGeo.latitude, toGeo.
  try {
    if (
      fromGeo.latitude &&
      fromGeo.longitude &&
      toGeo.latitude &&
      toGeo.longitude
    ) {
      if (
        isNaN(fromGeo.latitude) ||
        isNaN(fromGeo.longitude) ||
        isNaN(toGeo.latitude) ||
        isNaN(toGeo.longitude)
      )
        return { km: 0, miles: 0, usable: false };
      const fromLat = Number(fromGeo.latitude);
      const fromLng = Number(fromGeo.longitude);
      const toLat = Number(toGeo.latitude);
      const toLng = Number(toGeo.longitude);
      const lat = fromLat - toLat;
      const lng = fromLng - toLng;
      const dLat = (lat * Math.PI) / 180;
      const dLng = (lng * Math.PI) / 180;
      const toLatRad = (toLat * Math.PI) / 180;
      const fromLatRad = (fromLat * Math.PI) / 180;

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toLatRad) *
          Math.cos(fromLatRad) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = EARTHRADIUS * c;
      if (d === 0) return { km: 0, miles: 0, usable: false };
      let km = round(Number(d / 1000), 1);
      let miles = round(Number((d / 1000) * 0.621371), 1);
      return { km, miles, usable: true };
    } else {
      return { km: 0, miles: 0, usable: false };
    }
  } catch (e) {
    console.log("error while calculating distance", e);
    return { km: 0, miles: 0, usable: false };
  }
}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function displaySlotsByStep2(step, sm, tmap, serviceDuration, stiEtiArr) {
  let stepstarted = false;
  const steplen = step / SLOTUNITMINS;
  const serviceDurationlen = serviceDuration / SLOTUNITMINS;
  let returnStartTimes = [];

  let startIndex = 288,
    endIndex = 0;
  for (let stiEti of stiEtiArr) {
    let localEndIndex = stiEti.eti < stiEti.sti ? SLOTSINADAY : stiEti.eti;
    if (stiEti.sti < startIndex) startIndex = stiEti.sti;
    if (localEndIndex > endIndex) endIndex = localEndIndex;
  }
  const foundStartTimes = [];
  for (let s = startIndex; s < endIndex; s++) {
    if (stepstarted && sm[s] === 1) {
      for (let fst of foundStartTimes) {
        if (fst.live) fst.uc += 1;
      }
    } else {
      for (let fst of foundStartTimes) {
        fst.live = false;
      }

      stepstarted = false;
    }
    if ((s - startIndex) % steplen === 0) {
      //start of timeslot as per the configuration of step size
      if (sm[s] === 1) {
        foundStartTimes.push({ st: tmap[s], uc: 1, live: true });
        stepstarted = true;
      }
    }
  }
  returnStartTimes = returnStartTimes.concat(
    foundStartTimes
      .filter((fst) => fst.uc >= serviceDurationlen)
      .map((fst) => fst.st)
  );

  return returnStartTimes;
}

function buildTimeMap() {
  const tmap = new Array(SLOTSINADAY);
  for (let s = 0; s < SLOTSINADAY; s++) {
    const hr = Math.floor(s / SLOTSINHOUR);
    const mins = (s % SLOTSINHOUR) * SLOTUNITMINS;
    const tstr24 = `${hr < 10 ? "0" : ""}${hr}:${mins < 10 ? "0" : ""}${mins}`;
    const tfd =
      hr > 6 && hr < 12
        ? 1
        : hr > 11 && hr < 17
        ? 2
        : hr > 16 && hr <= 23
        ? 3
        : 4;
    const tstr12 = `${hr < 13 ? hr : hr - 12}:${mins < 10 ? "0" : ""}${mins} ${
      hr > 11 ? "PM" : "AM"
    }`;
    tmap[s] = { tstr24, tstr12, tfd, hr, mins };
    //console.log(s, tmap[s]);
  }
  return tmap;
}

const _getTimeblocks = async (filter) => {
  const { scheduleId, type } = filter;
  const pksk = scheduleId.split(SCHEDPKSKSPLITAT);

  const response = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: { scheduleinfo: { beginsWith: `SB|${pksk[1]}` } },
    filter: { type: { eq: type } },
    limit: 20,
  });
  const returnTimeblocks =
    response.items &&
    response.items.map((schedule) => {
      return {
        ...schedule,
        id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo, //to support DataTableWrapper
      };
    });
  return returnTimeblocks;
};

const getScheduleData = async (schId) => {
  const pksk = schId.split(SCHEDPKSKSPLITAT);
  const comp_prov = pksk[0].split("|");
  const companyId = comp_prov[0].substring(2);
  const providerId = comp_prov[1].substring(2);
  const schedules = await _getSchedules({ companyId, providerId });
  const returnSchedule = schedules.filter((sch) => sch.id === schId);
  return returnSchedule[0];
};
const _getProviderBookings = async (providerId) => {
  const limit = 50;
  let filter = {
    providerId: { eq: providerId },
  };
  let bookingsList = {};
  bookingsList = await API.graphql(
    graphqlOperation(listBookingsQuery, { filter, limit })
  );
  if (
    bookingsList.data.listBookings &&
    bookingsList.data.listBookings.items &&
    bookingsList.data.listBookings.items.length > 0
  ) {
    return bookingsList.data.listBookings.items;
  } else {
    return false;
  }
};
const _getSchedulesTime = async (filter) => {
  let schedules = await _getSchedules(filter);
  let bookings = await _getProviderBookings(filter.providerId);

  const blocks = schedules.map(async (item, i) => {
    let blocks = [];

    const availableTimeBlocks = await _getTimeblocks({
      scheduleId: item.id,
      type: "AVAILABLE",
    });
    const unAvailableTimeBlocks = await _getTimeblocks({
      scheduleId: item.id,
      type: "UNAVAILABLE",
    });
    // Available TimeBlocks
    for (const timeblock of availableTimeBlocks) {
      const startDate = new Date(timeblock.startDate);
      const endDate = new Date(timeblock.endDate);
      const DifferenceInDays =
        (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      const startTime = timeblock.startTime.split(":");
      const endTime = timeblock.endTime.split(":");
      let loopDay = new Date();
      loopDay.setHours(0, 0, 0, 0);
      if (loopDay.getTime() < startDate.getTime()) {
        loopDay = startDate;
      }
      for (let i = 0; i <= DifferenceInDays; i++) {
        const dayOfWeek = loopDay.getDay();
        if (timeblock.weekDays.includes(dayOfWeek)) {
          blocks.push({
            title: "AVAILABLE",
            start: new Date(
              loopDay.getFullYear(),
              loopDay.getMonth(),
              loopDay.getDate(),
              startTime[0],
              startTime[1],
              0,
              0
            ),
            end: new Date(
              loopDay.getFullYear(),
              loopDay.getMonth(),
              loopDay.getDate(),
              endTime[0],
              endTime[1],
              0,
              0
            ),
          });
        }
        if (loopDay.getTime() === endDate.getTime()) {
          break;
        }
        loopDay.setDate(loopDay.getDate() + 1);
      }
    }
    // UNAvailable TimeBlocks
    for (const timeblock of unAvailableTimeBlocks) {
      const startDate = new Date(timeblock.startDate);
      const endDate = new Date(timeblock.endDate);
      const DifferenceInDays =
        (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      const startTime = timeblock.startTime.split(":");
      const endTime = timeblock.endTime.split(":");
      let loopDay = new Date();
      loopDay.setHours(0, 0, 0, 0);
      if (loopDay.getTime() < startDate.getTime()) {
        loopDay = startDate;
      }
      for (let i = 0; i <= DifferenceInDays; i++) {
        const dayOfWeek = loopDay.getDay();
        if (timeblock.weekDays.includes(dayOfWeek)) {
          blocks.push({
            title: "UNAVAILABLE",
            start: new Date(
              loopDay.getFullYear(),
              loopDay.getMonth(),
              loopDay.getDate(),
              startTime[0],
              startTime[1],
              0,
              0
            ),
            end: new Date(
              loopDay.getFullYear(),
              loopDay.getMonth(),
              loopDay.getDate(),
              endTime[0],
              endTime[1],
              0,
              0
            ),
          });
        }
        if (loopDay.getTime() === endDate.getTime()) {
          break;
        }
        loopDay.setDate(loopDay.getDate() + 1);
      }
    }
    // Bookings TimeBlocks
    if (bookings) {
      for (const booking of bookings) {
        const startDate = new Date(booking.startdate);
        const endDate = new Date(startDate.getTime() + booking.minutes * 60000);
        const timeblockidparts = booking.timeblockid.split("::");
        const scheduleIdIsInsideHere =
          timeblockidparts[timeblockidparts.length - 1];
        const bookingScheduleId = scheduleIdIsInsideHere.substring(
          scheduleIdIsInsideHere.indexOf("SC|")
        );

        if (item.id === bookingScheduleId) {
          blocks.push({
            title: booking.desc,
            start: startDate,
            end: endDate,
          });
        }
      }
    }
    return { blocks: blocks };
  });

  return blocks;
};
//For UI : _getSchedules
const _getSchedules = async ({ companyId, providerId }) => {
  const response = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listAllScheduleItems,
    id: { id: getScheduleTimeblockId(companyId, providerId) },
    skey: { scheduleinfo: { beginsWith: "S" } },
    filter: { deleted: { ne: true }, active: { eq: true } },
    limit: process.env.REACT_APP_LISTLIMIT,
  });

  //TODO: check if no items in the response

  const allSchedules = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SC|")
  );

  //
  const pls = allSchedules
    .filter((sch) => sch.scheduleinfo.includes("|PL-"))
    .map((sch) => sch.locationId);
  const cls = allSchedules
    .filter((sch) => sch.scheduleinfo.includes("|CL-"))
    .map((sch) => sch.locationId);
  //

  //const allLocations = allSchedules.map(actualSch => actualSch.locationId);

  const uniqueLocations = [...new Set(cls)];

  const allServices = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SR|")
  );
  const allTBs = response.items.filter((schitem) =>
    schitem.scheduleinfo.startsWith("SB|")
  );

  //Get Locations
  const ORlocations = uniqueLocations.map((lId) => {
    return {
      id: {
        eq: lId,
      },
    };
  });
  const locationsFilter = {
    or: ORlocations,
  };
  const locations = await execRead({
    opname: "listCompanyLocations",
    op: queries.listCompanyLocations,
    filter: locationsFilter,
    limit: process.env.REACT_APP_LISTLIMIT,
  });

  const pd = await getProviderDataForSchedules(providerId);

  const returnSchedules = allSchedules.map((schedule) => {
    return {
      ...schedule,
      id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo, //to support DataTableWrapper
      tbs: allTBs
        .filter((tb) => tb.scheduleinfo.includes(schedule.scheduleinfo))
        .map((tb) => {
          createScheduleDescription(tb);
          return {
            ...tb,
            id: tb.id + SCHEDPKSKSPLITAT + tb.scheduleinfo,
          };
        }),
      location: schedule.scheduleinfo.includes("|PL-")
        ? getProviderLocationName(pd.locations, schedule.locationId)
        : getByValue(locations.items, schedule.locationId),
      services: allServices
        .filter((serv) => serv.scheduleinfo.endsWith(schedule.scheduleinfo))
        .map((serv) => {
          return {
            scheduleinfo: serv.scheduleinfo,
            servicetypeId: serv.servicetypeId,
            name: getServiceName(pd.servicetypes, serv.servicetypeId),
          };
        }),
    };
  });
  return returnSchedules;
};

function getProviderLocationName(locations, locationId) {
  let locationname = "Provider Location";
  const ps = locations.forEach((pl) => {
    if (locationId === pl.id) {
      locationname = pl.name;
    }
  });
  return {
    id: locationId,
    locationname,
  };
}

function getServiceName(providerServices, servicetypeId) {
  let serviceTypeName = null;
  const ps = providerServices.forEach((st) => {
    if (servicetypeId === st.servicetype.id) {
      serviceTypeName = st.servicetype.name;
    }
  });
  return serviceTypeName;
}
function getScheduleTimeblockId(cid, pid) {
  return `C-${cid}|P-${pid}`;
}
const getProviderSchedulesByLocationByService = async ({
  companyId,
  locationId,
  servicetypeId,
  providers,
}) => {
  let returnProviders = [];

  //TODO: improve - read schedules only if there is atleast one AVAILABLE TB
  const srs = await execReadBySortkey({
    opname: "schedulesByLocation",
    op: scheduleQueries.listAllScheduleItemsByLocation,
    id: { locationId },
    skey: { scheduleinfo: { beginsWith: `SR|${servicetypeId}` } },
    filter: { active: { eq: true } },
    limit: 20,
  });

  if (srs && srs.items && srs.items.length > 0) {
    returnProviders = providers.filter((provider) => {
      let found = false;
      srs.items.forEach((sr) => {
        if (sr.id.endsWith(provider.id)) found = true;
      });
      return found;
    });
  }

  return returnProviders;
};

const getProviderDataForSchedules = async (providerid) => {
  const providerData = await API.graphql(
    graphqlOperation(scheduleQueries.providerDataForScheduleQuery, {
      id: providerid,
    })
  );

  let providerDataForSchedule = {};

  if (
    providerData &&
    providerData.data &&
    providerData.data.getProvider &&
    providerData.data.getProvider.servicetypes &&
    providerData.data.getProvider.servicetypes.items
  ) {
    providerDataForSchedule = {
      ...providerDataForSchedule,
      servicetypes: providerData.data.getProvider.servicetypes.items.filter(
        (s) => !(true === s.servicetype.deleted)
      ),
    };
  }
  if (
    providerData &&
    providerData.data &&
    providerData.data.getProvider &&
    providerData.data.getProvider.locations &&
    providerData.data.getProvider.locations.items
  ) {
    providerDataForSchedule = {
      ...providerDataForSchedule,
      locations: providerData.data.getProvider.locations.items.filter(
        (pl) => !(true === pl.deleted)
      ),
    };
  }

  return providerDataForSchedule;
};

const getUnavailabilityOfProvider = async (filter) => {
  const { companyId, providerId } = filter;

  const response = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: getScheduleTimeblockId(companyId, providerId) },
    skey: { scheduleinfo: { beginsWith: `SBU|` } },
    filter: { type: { eq: "UNAVAILABLE" } },
    limit: 20,
  });
  const returnTimeblocks = response.items.map((schedule) => {
    return {
      ...schedule,
      id: schedule.id + SCHEDPKSKSPLITAT + schedule.scheduleinfo, //to support DataTableWrapper
    };
  });
  return returnTimeblocks;
};

async function checkPackageBookedSlotsValid(bookingState) {
  const slots = bookingState.heldPackageSlots;
  const bookings = bookingState.packageBookedSlots;
  if (slots?.length !== bookings?.length) {
    return false;
  }
  //if each slot exists in the db
  for (let slot of slots) {
    const idsplit = slot.id.split("::");
    const result = await API.graphql(
      graphqlOperation(
        `query GetScheduleTimeblock($id: ID!, $scheduleinfo: String!) {
            getScheduleTimeblock(id: $id, scheduleinfo: $scheduleinfo) {
              id
              scheduleinfo
            }
          }`,
        {
          id: idsplit[0],
          scheduleinfo: idsplit[1],
        }
      )
    );
    if (result.data.getScheduleTimeblock?.scheduleinfo === slot.scheduleinfo)
      console.log(slot.id, slot.scheduleinfo, " FOUND ");
    else return false;
  }
  // check if order of slots and bookings match in the respective arrays
  let index = 0;
  for (let b of bookings) {
    if (b.dateInfo.dtstamp_str !== slots[index]?.sdtutc) {
      return false;
    }
    index = index + 1;
  }
  return true;
}
async function checkSelectedSlotStillAvailable(bookingState, company) {
  const pksk = bookingState.selectedScheduleId.split(SCHEDPKSKSPLITAT);
  let startDate = new Date(bookingState.selectedslot.date);
  startDate = getAWSDate(startDate);
  let startTime = bookingState.selectedslot.label;
  const bookedResp = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: {
      scheduleinfo: { beginsWith: `BK|${startDate} ${startTime}` },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });

  if (bookedResp && bookedResp.items) {
    if (bookedResp.items.length) {
      const locationTz = await getLocationTimezone(pksk[1]);

      let allSlotsHaveTz = true;
      let slotTzMatch = false;

      for (let slot of bookedResp.items) {
        if (!slot.tz) allSlotsHaveTz = false;
        if (slot.tz === locationTz) {
          slotTzMatch = true;
        }
      }
      if (allSlotsHaveTz) return !slotTzMatch;

      return false;
    }
  }
  return true;
}

async function checkSlotStillAvailablePkgBkgFlow(
  selectedslot,
  selectedScheduleId
) {
  const pksk = selectedScheduleId.split(SCHEDPKSKSPLITAT);
  let startDate = new Date(selectedslot.date);
  startDate = getAWSDate(startDate);
  let startTime = selectedslot.label;
  const bookedResp = await execReadBySortkey({
    opname: "listScheduleTimeblocks",
    op: scheduleQueries.listTimeblocks,
    id: { id: pksk[0] },
    skey: {
      scheduleinfo: { beginsWith: `BK|${startDate} ${startTime}` },
    },
    filter: { status: { ne: "CANCELLED" } },
    limit: 500,
  });

  if (bookedResp && bookedResp.items) {
    if (bookedResp.items.length) {
      const locationTz = await getLocationTimezone(pksk[1]);

      let allSlotsHaveTz = true;
      let slotTzMatch = false;

      for (let slot of bookedResp.items) {
        if (!slot.tz) allSlotsHaveTz = false;
        if (slot.tz === locationTz) {
          slotTzMatch = true;
        }
      }
      if (allSlotsHaveTz) return !slotTzMatch;

      return false;
    }
  }
  return true;
}

function getPhysicalLocationGeoLoc(serviceLocationObj) {
  if (serviceLocationObj) {
    let locObj = serviceLocationObj;
    if (locObj.latitude && locObj.longitude) {
      return {
        lat: locObj.latitude,
        lng: locObj.longitude,
      };
    }
  }
  return;
}

async function getClusteredSlots(
  bookingState,
  lookAheadDays = DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING,
  startDate = new Date(),
  includeFullDayAnyTimeSlots = false
) {
  try {
    let timeSuggestionsEnabled = false;
    let firstBookingAnchorTime;
    let maxTravelTimeMinutes;
    if (bookingState && bookingState.company?.SuggestionConfig) {
      const suggestionConfigObj = JSON.parse(
        bookingState.company.SuggestionConfig
      );
      timeSuggestionsEnabled = suggestionConfigObj.isEnabled;
      firstBookingAnchorTime = suggestionConfigObj.timeOfFirstBookingOfTheDay;
      maxTravelTimeMinutes = suggestionConfigObj.maxTravelTimeMinutes;
    }
    if (timeSuggestionsEnabled) {
      let geoLoc = bookingState.isRemoteLocation
        ? bookingState.remoteAddressCoordinates
        : getPhysicalLocationGeoLoc(bookingState.serviceLocationObj);
      if (geoLoc) {
        const suggestedSlots = await API.post("bookingapi", "/clusteredslots", {
          body: {
            scheduleid: `${bookingState.provider.schedule.id}${SCHEDPKSKSPLITAT}${bookingState.provider.schedule.scheduleinfo}`,
            startdate: getAWSDate(startDate),
            numdays: lookAheadDays,
            bookingIntervalMinutes: bookingState.company.bookingIntervalMinutes,
            serviceDuration: bookingState.serviceType.minutes,
            maxTravelTime: maxTravelTimeMinutes, // Provider.maxTravelTime (when becomes available) or company.SuggestionConfig.maxTravelTimeMinutes
            geoLoc,
            bookingIncrement: 15,
            BROWSER_TZ: Intl.DateTimeFormat().resolvedOptions().timeZone, //BROWSER_TZ
            firstBookingAnchorTime,
            includeFullDayAnyTimeSlots,
          },
        });
        //ensure unique slots
        if (
          suggestedSlots &&
          suggestedSlots.clusteredslots &&
          suggestedSlots.clusteredslots[0]?.clusteredAvailability
        ) {
          const clusteredSlotsInfo =
            suggestedSlots.clusteredslots[0]?.clusteredAvailability;
          if (clusteredSlotsInfo.slots?.length) {
            const seenDates = new Set();
            clusteredSlotsInfo.slots = clusteredSlotsInfo.slots.filter(
              (obj) => {
                if (seenDates.has(obj.clusteredReadableSlot.datetime)) {
                  return false;
                } else {
                  seenDates.add(obj.clusteredReadableSlot.datetime);
                  return true;
                }
              }
            );
          }
        }
        return suggestedSlots;
      }
    }
  } catch (e) {
    console.log("getClusteredSlots error:", e);
  }
}
async function getTimeSuggestions(
  bookingState,
  lookAheadDays = DEFAULT_LOOKAHEAD_DAYS_FOR_CLUSTERING,
  startDate = new Date(),
  maxNumberOfSlots = DEFAULT_MAX_NUM_OF_SUGGESTED_SLOTS_TO_DISPALY
) {
  try {
    const result = await getClusteredSlots(
      bookingState,
      lookAheadDays,
      startDate,
      false
    );
    if (result) {
      if (
        result?.clusteredslots &&
        result.clusteredslots[0]?.clusteredAvailability
      ) {
        let clusteredSlotsInfo =
          result.clusteredslots[0]?.clusteredAvailability;
        if (clusteredSlotsInfo.slots?.length > maxNumberOfSlots)
          clusteredSlotsInfo.slots = clusteredSlotsInfo.slots.slice(
            0,
            maxNumberOfSlots
          );
        clusteredSlotsInfo.schedule = result.clusteredslots[0].schedule;
        return clusteredSlotsInfo;
      }
    } else {
      return {
        slots: [],
        fullDays: [],
      };
    }
  } catch (e) {
    console.log(e);
    return {
      slots: [],
      fullDays: [],
    };
  }
}

async function getSameDayClusteredSlots(bookingState) {
  try {
    const result = await getClusteredSlots(bookingState, 1, new Date(), true);
    if (
      result?.clusteredslots &&
      result.clusteredslots[0]?.clusteredAvailability
    ) {
      const { id: scheduleId } = result.clusteredslots[0]?.schedule;
      const clusteredAvailability =
        result.clusteredslots[0]?.clusteredAvailability;
      if (clusteredAvailability.slots?.length) {
        let sdSlots = clusteredAvailability.slots.map(
          (sds) => sds.clusteredReadableSlot
        );
        if (sdSlots.length > 3) sdSlots = sdSlots.slice(0, 3);
        sdSlots = sdSlots.map((sl) => {
          sl.datetimeStr = sl.datetime;
          sl.datetime = new Date(sl.datetime);
          return sl;
        });
        return { scheduleId, sameDayClusteredSlots: sdSlots };
      }
    }
  } catch (e) {
    console.log(e);
  }
}

async function getPackageSuggestedTimes(apptDates, bookingState) {
  try {
    const suggestedPackageDates = [];
    for (let apptDate of apptDates) {
      const result = await getClusteredSlots(
        bookingState,
        1,
        apptDate.date,
        false
      );
      if (result) {
        if (
          result?.clusteredslots &&
          result.clusteredslots[0]?.clusteredAvailability
        ) {
          let clusteredSlotsInfo =
            result.clusteredslots[0]?.clusteredAvailability;
          if (clusteredSlotsInfo.fullDays.length === 1) {
            // check YYYY-MM-DD of apptDate.date with fullDays[0].date's YYYY-MM-DD
            if (
              moment(apptDate.date).format("YYYY-MM-DD") ===
              moment(clusteredSlotsInfo.fullDays[0].date).format("YYYY-MM-DD")
            )
              suggestedPackageDates.push(apptDate); //display date info is already there
          }
          if (clusteredSlotsInfo.slots?.length) {
            const milisecondsOfCurrentApptDate = new Date(
              apptDate.date
            ).valueOf();
            for (let suggestedSlot of clusteredSlotsInfo.slots) {
              const clusteredApptMilisconds = new Date(
                suggestedSlot.clusteredReadableSlot.datetime
              ).valueOf();
              suggestedSlot.clusteredReadableSlot.diffToUserSelectedDateTime =
                Math.abs(
                  clusteredApptMilisconds - milisecondsOfCurrentApptDate
                );
            }
            clusteredSlotsInfo.slots.sort((s1, s2) => {
              if (
                s1.clusteredReadableSlot.diffToUserSelectedDateTime >
                s2.clusteredReadableSlot.diffToUserSelectedDateTime
              )
                return 1;
              else if (
                s1.clusteredReadableSlot.diffToUserSelectedDateTime <
                s2.clusteredReadableSlot.diffToUserSelectedDateTime
              )
                return -1;
              else return 0;
            });
            const suggestedSlotToBePresented =
              clusteredSlotsInfo.slots[0].clusteredReadableSlot;
            suggestedSlotToBePresented.datetime = new Date(
              suggestedSlotToBePresented.datetime
            );
            suggestedPackageDates.push({
              date: suggestedSlotToBePresented.datetime,
              key: suggestedSlotToBePresented.datetime.toISOString(),
              selected: true,
              validity: {
                isValid:
                  suggestedSlotToBePresented.diffToUserSelectedDateTime === 0
                    ? true
                    : false,
                hasAlternate:
                  suggestedSlotToBePresented.diffToUserSelectedDateTime === 0
                    ? false
                    : true,
                originalSlot: apptDate.date,
                dateInSchedule: true,
                ...(suggestedSlotToBePresented.diffToUserSelectedDateTime && {
                  alternateSlot: {
                    date: suggestedSlotToBePresented.datetime,
                    slot: suggestedSlotToBePresented,
                  },
                }),
              },
            });
          }
        }
      }
    }
    return suggestedPackageDates;
  } catch (e) {
    console.log("error in getPackageSuggestedTimes", e);
  }
}
export {
  createSchedule,
  updateSchedule,
  createTimeblock,
  updateTimeblock,
  getNowDate, //returns browser local TZ now date in YYYY-MM-DD format
  getAWSDateOf, //returns a give given date in YYYY-MM-DD format
  awsDateToJsDate, // turns YYYY-MM-DD formated date into JS date object of browser local TZ
  getAWSDate, //returns a given date in YYYY-MM-DD format
  validateApptDate,
  _getSchedules,
  _getTimeblocks,
  getProviderSchedulesByLocationByService,
  getProviderDataForSchedules,
  getScheduleData,
  getUnavailabilityOfProvider,
  createUnavailableTimeblock,
  SCHEDPKSKSPLITAT,
  _getSchedulesTime,
  getTimeblocksbyScheduleId,
  getTimeblocksbyScheduleIdNoCache,
  getAnyLocationObj,
  getScheduleCounts,
  checkServiceHasExistingSchedule,
  activateSchedule,
  deactivateSchedule,
  getSBsByScheduleInfo,
  getProviderLocation,
  getCompanyLocation,
  getSlotsByScheduleId,
  checkSelectedSlotStillAvailable,
  checkSlotStillAvailablePkgBkgFlow,
  calcDist,
  checkPackageBookedSlotsValid,
  calculateBookedMinutes,
  getEndingDate,
  getLocationTimezone,
  getTimeSuggestions,
  getSameDayClusteredSlots,
  getPackageSuggestedTimes,
};
