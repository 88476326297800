import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainNavBar from "../components/MainNavbar";
import { withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import API, { graphqlOperation } from "@aws-amplify/api";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { trackRegistration, trackLogin } from "../modules/Tracking";
import Cache from "@aws-amplify/cache";
import {
  getUser,
  userByCompany,
  clientByUserId,
  clientPackageByUser,
} from "../graphql/queries";
import {
  createUser,
  updateUser,
  updateClient,
  updateClientPackage,
} from "../graphql/mutations";
import {
  getClientIfExists,
  createClientRecord,
  deleteHeldSlots,
} from "../modules/BookingService";
import CircularProgress from "@mui/material/CircularProgress";
import {
  isGuestCheckoutAllowed,
  isGuestOnlyCheckout,
} from "../user/checkoutOption";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";

const text_field_style = { width: "100%", maxWidth: "300px" };

function SignIn(props) {
  const { actions, state } = useContext(StoreContext);
  const [email, setEmail] = useState("");
  const [pasw, setPasw] = useState("");
  const [loginClicked, setLoginClicked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [newPaswRequired, setNewPaswRequired] = useState(false);
  const [newPasw, setNewPasW] = useState();
  const [user, setUser] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const smallerScreen = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    console.log("Props to SignIN", props);
  }, [props]);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const backButtonHandler = async () => {
    console.log("Back button clicked");
    if (props?.location?.state?.fromPath) {
      if (props.location.state.fromPath === "/confirm-order") {
        try {
          if (state.bookingState.sdt === "package") {
            if (state.bookingState.heldPackageSlots) {
              await deleteHeldSlots(state.bookingState.heldPackageSlots);
              state.bookingState.heldPackageSlots = [];
              if (state.bookingState.hasOwnProperty("packageBookedSlots")) {
                state.bookingState.packageBookedSlots = [];
              }
            }
          }

          if (state.bookingState.sdt === "forever") {
            await deleteHeldSlots(state.bookingState.heldSlots);
            state.bookingState.heldSlots = [];
            state.bookingState.apptDates = [];
            state.bookingState.repeatingApptList = [];

            // state.bookingState.repeatStartDate = null;
            //Appears to be some degredation of information across here?
          }

          if (state.bookingState.sdt === "single") {
            await deleteHeldSlots(state.bookingState.heldSlots);
            state.bookingState.heldSlots = [];
            /* if (state.bookingState.hasOwnProperty("selectedslot")) {
              state.bookingState.selectedslot = {};
            } */
          }
        } catch (e) {
          console.log("error while deleting the slots");
        }
        props.history.push("/choose-timeslot");
        return;
      }
      props.history.push(props.location.state.fromPath);
    }
  };

  async function handleChangePassword() {
    try {
      setLoginClicked(true);
      const userWithNewPassword = await Auth.completeNewPassword(user, newPasw);
      console.log("userWithNewPassword", userWithNewPassword);
      await doPostSignInProcess();
    } catch (err) {
      setLoginClicked(false);
      setErrorMsg(
        err.message
          ? err.message
          : "There was an error in processing your request."
      );
    }
  }

  const handleEnterKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSignin();
    }
  };
  async function handleSignin() {
    setLoginClicked(true);
    try {
      const user = await Auth.signIn(email, pasw);
      setUser(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewPaswRequired(true);
        setLoginClicked(false);
      } else {
        await doPostSignInProcess();
      }
    } catch (err) {
      if (err.code === "PasswordResetRequiredException") {
        console.debug("the user requires a new password");
        props.history.push("/forgot", {
          fromPath: props.location.state?.fromPath
            ? props.location.state?.fromPath
            : "/",
          stage: 2,
          email,
        });
      }
      console.log("error", err);
      setLoginClicked(false);
      setErrorMsg(`Login failed. ${err.message ? err.message : ""}`);
      return;
    }
  }

  async function doPostSignInProcess() {
    setLoginClicked(true);

    // look up user and store
    const authUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    if (authUser.attributes.email === process.env.REACT_APP_MB_GUEST_EMAIL) {
      console.log("non-guest user not logged in");
      setLoginClicked(false);
      return;
    }
    const data = await API.graphql(
      graphqlOperation(getUser, {
        id: authUser.username,
      })
    );
    let clientRecord;
    if (data.data.getUser) {
      if (
        data.data.getUser.companyId !== props.company.id &&
        data.data.getUser.role !== "MARKETBOX_ADMIN"
      ) {
        setErrorMsg(
          "Please create an account with different email address using the Sign Up link above."
        );
        Cache.removeItem("user");
        // logout the user of different company
        await Auth.signOut();
        // log back the guest user in
        const user = await Auth.signIn(
          process.env.REACT_APP_MB_GUEST_EMAIL,
          process.env.REACT_APP_MB_GUEST_PWD
        );
        setLoginClicked(false);
        return;
      }
      trackLogin(data.data.getUser);
      const prevLoginInfo = JSON.parse(data.data.getUser.LoginInfo);
      const newLoginCount = prevLoginInfo ? ++prevLoginInfo.loginCount : 1;
      const loginInfo = {
        lastLoginDate: new Date(),
        loginCount: newLoginCount,
      };
      const LoginInfo = JSON.stringify(loginInfo);
      const updateMBUserInput = {
        role: data.data.getUser.role,
        emailaddress: data.data.getUser.emailaddress,
        id: authUser.username,
        LoginInfo,
      };
      await API.graphql(
        graphqlOperation(updateUser, { input: updateMBUserInput })
      );
      clientRecord = await getClientIfExists({
        userid: data.data.getUser.id,
        companyid: props.company.id,
      });
      if (!clientRecord) {
        const clientRecord = await createClientRecord(
          data.data.getUser,
          props.company
        );
        if (clientRecord && clientRecord.error) {
          setLoginClicked(false);
          return {
            error: clientRecord.error,
          };
        }
        props.setUser(authUser);
        props.setClient({
          isNewClient: true,
          client: clientRecord,
        });
      } else {
        props.setUser(authUser);
        props.setClient({
          isNewClient: false,
          client: clientRecord,
        });
      }
    } else {
      await handleNewUserLogin();
    }
    console.log(props);
    props.history.push(
      props.location.state?.fromPath ? props.location.state?.fromPath : "/"
    );
  }
  async function addUserRole(username, groupName, action) {
    const result = await API.post("changeuserrole", "/changeuserrole", {
      body: {
        username,
        groupName,
        action,
      },
    });
  }

  async function handleNewUserLogin() {
    // console.log("signing in new user");
    const newuser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    if (newuser.attributes.email === process.env.REACT_APP_MB_GUEST_EMAIL) {
      console.log("new non-guest user login is unsuccessful");
      setLoginClicked(false);
      return false;
    }
    await addUserRole(email, "Client", "ADD");
    await Auth.signOut();
    await Auth.signIn(email, pasw);
    const newloggedinuser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    const loginInfo = {
      lastLoginDate: new Date(),
      loginCount: 1,
    };
    const LoginInfo = JSON.stringify(loginInfo);
    const newMBUserInput = {
      username: newloggedinuser.username,
      id: newloggedinuser.signInUserSession.idToken.payload.sub,
      emailaddress: newloggedinuser.signInUserSession.idToken.payload.email,
      firstname: newloggedinuser.signInUserSession.idToken.payload.given_name,
      lastname: newloggedinuser.signInUserSession.idToken.payload.family_name,
      registered: true,
      active: true,
      role: "CLIENT",
      companyId: props.company.id,
      userCompanyId: props.company.id,
      mobilephone:
        newloggedinuser.signInUserSession.idToken.payload.phone_number,
      LoginInfo,
      phonepref: "MOBILE",
      contactconsent: true,
      contactconsentdatetime: new Date().toISOString(),
    };
    const newMBUser = await API.graphql(
      graphqlOperation(createUser, { input: newMBUserInput })
    );
    trackRegistration(newMBUser && newMBUser.data && newMBUser.data.createUser);
    trackLogin(newMBUser && newMBUser.data && newMBUser.data.createUser);
    const usersByCompanyResult = await API.graphql(
      graphqlOperation(userByCompany, {
        companyId: props.company.id,
        roleEmailaddress: {
          beginsWith: {
            role: "CLIENT",
            emailaddress:
              newloggedinuser.signInUserSession.idToken.payload.email,
          },
        },
        filter: {
          and: [
            {
              emailaddress: {
                eq: newloggedinuser.signInUserSession.idToken.payload.email,
              },
            },
            { registered: { eq: false } },
            { deleted: { ne: true } },
          ],
        },
        limit: 1000,
      })
    );
    //console.log("users by company result register", usersByCompanyResult);

    if (
      usersByCompanyResult &&
      usersByCompanyResult.data.userByCompany.items.length > 0
    ) {
      const clientData = usersByCompanyResult.data.userByCompany.items[0];
      const clientPackagesResult = await API.graphql(
        graphqlOperation(clientPackageByUser, {
          userId: clientData.id,
        })
      );
      // console.log("client packages", clientPackagesResult);

      if (
        clientPackagesResult &&
        clientPackagesResult.data.clientPackageByUser.items.length > 0
      ) {
        clientPackagesResult.data.clientPackageByUser.items.map(
          async (item) => {
            const updateClientPackageResult = await API.graphql(
              graphqlOperation(updateClientPackage, {
                input: {
                  id: item.id,
                  userId: newloggedinuser.signInUserSession.idToken.payload.sub,
                  clientPackageUserId:
                    newloggedinuser.signInUserSession.idToken.payload.sub,
                },
              })
            );
            //  console.log("update package result", updateClientPackageResult);
          }
        );
      }
      const deleteOldUserProfile = await API.graphql(
        graphqlOperation(updateUser, {
          input: { id: clientData.id, deleted: true },
        })
      );

      //    console.log("delete result", deleteOldUserProfile);
      const clientProfile = await API.graphql(
        graphqlOperation(clientByUserId, {
          userId: clientData.id,
        })
      );
      const clientId = clientProfile.data.clientByUserId.items[0].id;
      //    console.log("existing client profile", clientProfile);

      const updateClientResult = await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: clientId,
            userId: newloggedinuser.signInUserSession.idToken.payload.sub,
            clientUserId: newloggedinuser.signInUserSession.idToken.payload.sub,
          },
        })
      );
      //   console.log("update client result: ", updateClientResult);
      props.setUser(newloggedinuser);
      props.setClient({
        isNewClient: false,
        client: updateClientResult.data.updateClient,
      });
      setLoginClicked(false);
      return true;
    }
    const clientRecord = await createClientRecord(
      newMBUser.data.createUser,
      props.company
    );
    props.setUser(newloggedinuser);
    props.setClient({
      isNewClient: true,
      client: clientRecord,
    });
    setLoginClicked(false);
    return true;
  }
  return (
    <div>
      <MainNavBar
        company={props.company}
        user={null}
        guestUser={true}
        signOutOp={props.signOutOp}
        currentPath={props.location.pathname}
        banner={false}
        publicsitesettings={props.publicsitesettings}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "115px",
          padding: "0 1rem",
        }}
      >
        <Grid container justifyContent="flex-start">
          {smallerScreen && <Grid item xs={3}></Grid>}
          <Grid item>
            {/* component={Link} */}
            <Button
              sx={{
                cursor: "pointer",
                marginLeft: !smallerScreen ? "-1.25rem" : "unset",
              }}
              variant="text"
              size="small"
              startIcon={
                <KeyboardArrowLeft
                  size="small"
                  style={{ marginRight: "5px" }}
                />
              }
              color="primary"
              onClick={backButtonHandler}
            >
              Back
            </Button>
          </Grid>
          {smallerScreen && <Grid item xs={6}></Grid>}
        </Grid>

        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {`Welcome to ${props.company.name}`}
        </Typography>
        {newPaswRequired && (
          <>
            {errorMsg && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2" style={{ color: "red" }}>
                  {errorMsg}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  ...text_field_style,
                  alignSelf: "center",
                  maxWidth: !smallerScreen ? "unset" : "300px",
                  backgroundColor: "#fff",
                  "&.Mui-focused": {
                    backgroundColor: "#fff",
                  },
                  "&.Mui-focused .MuiInputAdornment-root": {
                    backgroundColor: "#fff",
                  },
                }}
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                id="newpassword"
                label="New password"
                onChange={(e) => {
                  setNewPasW(e.target.value);
                  if (errorMsg) setErrorMsg();
                }}
              />
            </Box>
            <Button
              variant="contained"
              sx={{ ...text_field_style }}
              onClick={handleChangePassword}
              disabled={loginClicked}
            >
              Change password
              {loginClicked && (
                <CircularProgress size={12} style={{ color: "#fff" }} />
              )}
            </Button>
          </>
        )}
        {!newPaswRequired && !isGuestOnlyCheckout(props.publicsitesettings) && (
          <>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <Typography variant="body2">Log in to your account</Typography>
              <Typography variant="body2">
                Don't have an account?{" "}
                <Box
                  sx={{
                    display: "inline",
                    fontWeight: 700,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    props.history.push("/signup", {
                      fromPath: props.location.state?.fromPath
                        ? props.location.state?.fromPath
                        : "/",
                    })
                  }
                >
                  Sign up
                </Box>{" "}
                instead
              </Typography>
            </Box>
            {errorMsg && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2" style={{ color: "red" }}>
                  {errorMsg}
                </Typography>
              </Box>
            )}
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="email"
              label="Email"
              onChange={(e) => {
                let ev = e.target.value;
                ev = ev.trim().toLowerCase();
                setEmail(ev);
                if (errorMsg) setErrorMsg();
              }}
              onKeyDown={handleEnterKeyDown}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                gap: "6px",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  ...text_field_style,
                  alignSelf: "center",
                  maxWidth: !smallerScreen ? "unset" : "300px",
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                size="small"
                id="password"
                label="Password"
                onChange={(e) => {
                  setPasw(e.target.value);
                  if (errorMsg) setErrorMsg();
                }}
                onKeyDown={handleEnterKeyDown}
              />

              <Box
                sx={{
                  ...text_field_style,
                  fontWeight: 700,
                  color: "primary.main",
                  cursor: "pointer",
                  alignSelf: "center",
                  fontFamily: "Roboto",
                  maxWidth: !smallerScreen ? "unset" : "300px",
                }}
                onClick={(event) =>
                  props.history.push("/forgot", {
                    fromPath: props.location.state?.fromPath
                      ? props.location.state?.fromPath
                      : "/",
                  })
                }
              >
                Forgot password?
              </Box>
            </Box>

            <Button
              variant="contained"
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
                marginTop: "1rem",
              }}
              onClick={handleSignin}
              disabled={loginClicked}
            >
              {loginClicked ? (
                <CircularProgress size={12} sx={{ color: "primary.main" }} />
              ) : (
                "Log In"
              )}
            </Button>
          </>
        )}
        {isGuestCheckoutAllowed(props.publicsitesettings) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            {!isGuestOnlyCheckout(props.publicsitesettings) && (
              <Typography variant="body2" sx={{ marginTop: "0.25rem" }}>
                OR
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={(event) =>
                props.history.push("/signup", {
                  fromPath: props.location.state?.fromPath
                    ? props.location.state?.fromPath
                    : "/",
                  guestMode: true,
                })
              }
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
            >
              Continue as guest
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
}

export default withRouter(SignIn);
